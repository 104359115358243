import { fromJS } from 'immutable'
export default function userReducer(
    state = fromJS({
        currentUser: 4,
        user: null
    }),
    action) {
    switch (action.type) {
        case 'USER_LOGIN_FINISHED':{
            return state.set('user',fromJS(action.payload))
        }
    }
    return state;
}
