import { fromJS } from 'immutable'
export default function firebaseReducer(
    state = fromJS({
        user: {
            uid: "N/A",
            providerData:"N/A",
            photoURL: "N/A",
            emailVerified: false,
            email: "N/A",
            userStatus:"loading",
            isUserSignedIn: false
        },
        sign_in_status: { isUserSignedIn: true },
        registration_status: { isUserRegistered: false },
        verification_status: { isUserVerified: true },
        route_to_registration_page: { toRegistrationPage: false },
        route_to_resetpassword_page: { toResetPasswordPage: false },
        user_twitter_tokens: {},
        handle_user_email_verification_status: null,
        handle_user_reset_password: null,
        new_user_registration_status: false


    }),
    action) {
    switch (action.type) {
        case 'FIREBASE_USER_SIGNED_IN': {
            return state.set('user', fromJS(action.payload))
        }
        case 'FIREBASE_USER_REGISTERED': {
            return state.set('registration_status', fromJS(action.payload))
        }

        case 'MENTIONS_FETCH_TWITTER_ACCESS_TOKENS_FINISHED': {
            return state.set('user_twitter_tokens', fromJS(action.payload))
        }
        case 'FIREBASE_ROUTE_TO_REGISTRATION_PAGE': {
            return state.set('route_to_registration_page', fromJS(action.payload))
        }
        case 'FIREBASE_ROUTE_TO_RESETPASSWORD_PAGE': {
            return state.set('route_to_resetpassword_page', fromJS(action.payload))
        }
        case 'FIREBASE_HANDLE_USER_EMAIL_VERIFICATION': {
            return state.set('handle_user_email_verification_status', fromJS(action.payload))
        }
        case 'FIREBASE_HANDLE_USER_RESET_PASSWORD': {
            return state.set('handle_user_reset_password', fromJS(action.payload))
        }
        case 'FIREBASE_NEW_USER_REGISTRATION_STATUS': {
            return state.set('new_user_registration_status', fromJS(action.payload))
        }



    }
    return state;
}
