import { fromJS } from 'immutable'
export default function settingsReducer(
    state = fromJS({
       usersmscontacts:[],
       usersettingspackage:{user_data:[],user_data_ready:true},
       userprofileinfo:{user_data:[],user_data_ready:true},


    }),
    action) {
    switch (action.type) {
        case "SETTINGS_FETCH_USER_SMS_CONTACTS_FINISHED":{
            return state.set('usersmscontacts',fromJS(action.payload))
        }
        case "SETTINGS_FETCH_USER_PACKAGE_FINISHED":{
            return state.set('usersettingspackage',fromJS(action.payload))
        }
        case "SETTINGS_FETCH_USER_PROFILE_INFO_FINISHED":{
            return state.set('userprofileinfo',fromJS(action.payload))
        }


        case "SETTINGS_INSERT_SMS_CONTACT_FINISHED":{
            return state.set('usersmscontacts',fromJS(action.payload))
        }
        case "SETTINGS_DELETE_USER_SMS_CONTACT_FINISHED":{
            return state.set('usersmscontacts',fromJS(action.payload))
        }
        
       
    }
    return state;
}
