import axios from "axios";

var api= axios.create({
    // baseURL: 'http://192.168.202.2:7070'
    //production URL: http://134.209.215.2:7070"
    //development URL: http://localhost:7070
    // https://app.brandmoran.com:7070
    baseURL: process.env.API_URL || "https://app.brandmoran.com:7070",
  });

api.interceptors.response.use(response => {
  //test if errors exist
  if (response.status === 260) {
    return Promise.reject(response.data);
  } else return response;
});
api.interceptors.request.use(request => {
  const token = localStorage.getItem('token');
  request.headers = {
    Authorization:
      "Bearer " + token
  }
  
  return request;
});

// api.interceptors.request.use(request => {
//   request.headers = {
//     Authorization:
//       "Bearer " +
//       (firebase.primary.auth().currentUser
//         ? firebase.primary.auth().currentUser.uid
//         : "ERROR_NOT_AUTHENTICATED"),
//   };
//   return request;
// });
export default api;
