import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import Auth from './containers/DefaultLayout/Auth'
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
// import Logout from './views/Pages/Logout/Logout';

//actions
import * as firebaseActions from './actions/firebaseActions'

//components
import ProtectedRoute from './ProtectedRoute'

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const PasswordReset = Loadable({
  loader: () => import('./views/Pages/PasswordReset'),
  loading
});

const VerifyMail = Loadable({
  loader: () => import('./views/Pages/VerifyMail'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const Logout = Loadable({
  loader: () => import('./views/Pages/Logout'),
  loading
});

//adding user management page to handle email verification and password reset

const Usermgmt = Loadable({
  loader: () => import('./views/Pages/Usermgmt'),
  loading
});


class App extends Component {
  

  componentWillMount() {
    this.props.dispatch(firebaseActions.checkLoggedInUser())
  }

  render() {

    const isAuthenticated = this.props.user &&this.props.user.get('isUserSignedIn') &&this.props.user.get('emailVerified')

    return (
      // Preffered BrowserRouter to HashRouter due to HashRouter requiring Hash(#) in url to work correctly
      <HashRouter>
        <Switch>
        <Route exact path="/logout" name="Logout" component={Logout} />
          <Route exact path="/login" name="Login Page" >
            {isAuthenticated ? (
              <Redirect to="/dashboard" />
            ) : <Login />}
          </Route>
          {
            //Only show Register and Reset Password pages to users who are not authenticated
            isAuthenticated ? null :
              [<Route exact path="/register" name="Register Page" component={Register} />,
              <Route exact path="/usermgmt" name="User Management Page" component={Usermgmt} />,
              <Route exact path="/resetpass" name="Logout" component={PasswordReset} />,
              <Route exact path="/verifymail" name="Verify Email" component={VerifyMail} />]
          }
          <Route path="/" >
            {isAuthenticated ? (
              <DefaultLayout />
            ) : <Redirect to="/login" />}
          </Route>
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            path="/dashboard"
            logout={null}
            component={DefaultLayout}
          />

          <Route exact path="/login" name="Login Page" >
            {isAuthenticated ? (
              <Redirect to="/dashboard" />
            ) : <Login />}
          </Route>



          


          {/*<Route exact path="/verifymail" name="Verify Email" component={VerifyMail} />
            <Route exact path="/resetpass" name="Reset Password" component={PasswordReset} />
            
            
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
             */}

        </Switch>
      </HashRouter>
    );
  }
}

export default
  connect((store) => {
    return {
      user: store.getIn(['firebase', 'user'])

    }
  })(App)
