import { combineReducers, applyMiddleware, createStore,  preloadedState,compose } from "redux";
import thunk from "redux-thunk";
import Immutable from "immutable";
import { createLogger } from "redux-logger";
import reducers from "../reducers/index";
import promise from "redux-promise-middleware";
// const thunk = require('redux-thunk')

let middleware = [thunk];
if (process.env.NODE_ENV !== "production") {
  const logger = createLogger({
    // stateTransformer: (state) => state.toJS()
  });
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  //     // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  //     actionCreators: {
  //         interfaceActions,
  //         patientActions,
  //         accountActions,
  //         drugActions,
  //         filesActions,
  //         firebaseActions,
  //         generalActions,
  //         historyActions,
  //         labActions,
  //         queueActions,
  //     }
  // }) : compose;
  middleware = [...middleware, logger]
  // middleware = applyMiddleware(...middleware)
  // middleware = composeEnhancers(applyMiddleware(...middleware))
} else {
  middleware = applyMiddleware(...middleware);
  /*
   *
   *Disable console.log in production
   *
   */
  console = {};
  console.log = function() {};
  console.error = function() {};
  console.warn = function() {};
  window.console = console;

  /*
   *
   *
   */
}

const store = createStore(reducers, middleware) 


// const store = createStore(
//   reducers,
//   Immutable.Map(),
//   compose(
//     applyMiddleware(...middleware),
//     ...[]
//   )
// );
export default store;

