import { fromJS } from 'immutable'
export default function twitterReducer(
    state = fromJS({
     twittermentions:[],
     twitterbubblechartdata:[],
     demoUsers:[]

    }),
    action) {
    switch(action.type){
        case 'TWITTER_FETCH_TWEETS_FINISHED':{
            return state.set('twittermentions',fromJS(action.payload))
        }
        case 'TWITTER_BUBBLE_CHART_DATA_FINISHED':{
            return state.set('twitterbubblechartdata',fromJS(action.payload))
        }
        case "TWITTER_FETCH_DEMO_USERS_FINISHED":{
            return state.set('demoUsers',fromJS(action.payload))
        }
    }
    return state;
}
