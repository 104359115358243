import { fromJS } from 'immutable'
export default function facebookReducer(
    state = fromJS({
        facebook_inbox: [],
        facebook_mentions: [],
        facebook_comments: [],
        facebook_thread_messages: []



    }),
    action) {
    switch (action.type) {
        case 'FACEBOOK_FETCH_USER_INBOX_MESSAGES_FINISH': {
            return state.set('facebook_inbox', fromJS(action.payload))
        }

        case 'FACEBOOK_FETCH_MENTIONS_FINISH': {
            console.log("in store updating facebook mentions", fromJS(action.payload))
            return state.set('facebook_mentions', fromJS(action.payload))
        }

        case 'FACEBOOK_FETCH_POST_COMMENTS_FINISH': {
            console.log("in store updating facebook post comments", fromJS(action.payload))
            // const index = state.findIndex((post, index) => {
            //     if (post.id == "my_id")
            //         return true
            // })
            return state.set('facebook_comments', fromJS(action.payload))
            // return state.setIn(['facebook_mentions', index, "comments"], fromJS(action.payload))
        }

        case 'FACEBOOK_FETCH_POST_COMMENTS_START': {
            console.log("in store updating facebook post comments", fromJS(action.payload))
            return state.set('facebook_comments', fromJS({ data: [] }))
        }
        case 'FACEBOOK_OPEN_MESSAGE_FINISH': {
            console.log("in store updating the mesages thread...", fromJS(action.payload))
            return state.set('facebook_thread_messages', fromJS(action.payload))
        }






    }
    return state;
}
