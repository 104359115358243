import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux'
import * as uiActions from '../../actions/userInterfaceActions'
import * as firebaseActions from '../../actions/firebaseActions'
import { Redirect } from 'react-router-dom';


class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentWillReceiveProps(newProps) {


    }
    componentWillMount() {
        this.props.dispatch(firebaseActions.checkLoggedInUser())
    }



    render() {

/*

1. Logged, send to Dashboard
2. Logged out, send to Login Page
3. 
*/

        console.log("this props route ", this.props)
        // if(true)
        // return(<div>Its working</div>)
        if (!this.props.sign_in_status.get('isUserSignedIn') && this.props.route_to_registration_page) {
            console.log("routing to registration page..")
            return <Redirect push to="/register" />;
        }
        if (!this.props.sign_in_status.get('isUserSignedIn') && this.props.route_to_resetpassword_page) {
            console.log("routing to registration page..")
            return <Redirect push to="/resetpass" />;
        }
        if (!this.props.sign_in_status.get('isUserSignedIn') && this.props.registration_status) {
            return <Redirect push to="/login" />;
        }
        if (this.props.sign_in_status.get('isUserSignedIn') && this.props.verification_status.get('isUserVerified')) {
            return <Redirect push to="/dashboard" />;
        }
        else {

            return <Redirect push to="/login" />;

        }




    }
}
export default connect((store) => {
    return {
        sign_in_status: store.getIn(['firebase', 'sign_in_status']),
        route_to_registration_page: store.getIn(['firebase', 'route_to_registration_page', 'toRegistrationPage']),
        registration_status: store.getIn(['firebase', 'registration_status']),
        verification_status: store.getIn(['firebase', 'verification_status']),
        route_to_resetpassword_page: store.getIn(['firebase', 'route_to_resetpassword_page', 'toResetPasswordPage'])

    }
})
    (Auth);