import axios from './axios'
import * as uiActions from './userInterfaceActions.js'
var firebase = require("firebase/app");

// const auth =firebase.auth()
require("firebase/auth");
require("firebase/firestore");

const firebaseConfig = {
    apiKey: "AIzaSyDF-KqH9_TqcyNwLFU5xab-XC6qzeKx_vw",
    authDomain: "brand-africa.firebaseapp.com",
    databaseURL: "https://brand-africa.firebaseio.com",
    projectId: "brand-africa",
    storageBucket: "brand-africa.appspot.com",
    messagingSenderId: "541012733777",
    appId: "1:541012733777:web:87897bc4e733dbb7a72d70"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth()


var provider = new firebase.auth.TwitterAuthProvider();




export function routeToRegistrationPage() {

    return (dispatch, getState) => {

        dispatch({ type: "FIREBASE_ROUTE_TO_REGISTRATION_PAGE", payload: { toRegistrationPage: true } })
    }
}
export function routeToResetPasswordPage() {

    return (dispatch, getState) => {

        dispatch({ type: "FIREBASE_ROUTE_TO_RESETPASSWORD_PAGE", payload: { toResetPasswordPage: true } })
    }
}
export function routeToLoginPage() {

    return (dispatch, getState) => {

        dispatch({ type: "FIREBASE_ROUTE_TO_REGISTRATION_PAGE", payload: { toRegistrationPage: false } })
    }
}

export function generateUserApiToken(userEmail) {
    return async (dispatch, getState) => {
        dispatch({ type: "FIREBASE_GENERATE_USERTOKEN_BEGIN" })
        await axios.get('/api/jwt', {
            userEmail
        })
            .then(function (response) {
                localStorage.setItem('token', response.data.token);
                dispatch({ type: "FIREBASE_GENERATE_USERTOKEN_FINISHED", payload: response.data })
            })
            .catch(function (error) {

                dispatch({ type: "FIREBASE_GENERATE_USERTOKEN_ERROR", payload: error })
            });

    }
}

export function signInUser(userEmail, userPassword) {

    return (dispatch) => {
        console.log("action to sign in user dispatched", userEmail, "...", firebase)

        firebase.auth().signInWithEmailAndPassword(userEmail, userPassword).then((response) => {


            dispatch(uiActions.showSnackbar({ color: "success", message: "PLEASE WAIT...." }))

            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    dispatch(generateUserApiToken(user.email))
                    console.log("user", user.email, "is logged and is user verfiied:", user.emailVerified);

                    dispatch({
                        type: "FIREBASE_USER_SIGNED_IN", payload: {

                            email: user.email,
                            emailVerified: user.emailVerified,
                            photoURL: user.photoURL,
                            isAnonymous: user.isAnonymous,
                            uid: user.uid,
                            providerData: user.providerData,
                            isUserSignedIn: true
                        }
                    })
                    dispatch(uiActions.showSnackbar({ color: "success", message: "LOGIN SUCCESS!" }))


                    //assign API toke to user for requests.

                }
            });
            // dispatch({ type: "FIREBASE_USER_SIGNED_IN", payload: { isUserSignedIn: true } })

        }).catch((error) => {
            dispatch(uiActions.showSnackbar({ color: "danger", message: "Login error please check your details " }))
            console.log("error occured when signing in new user:", error)
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
        });




    }
}


export function createNewUser(businessName, userEmail, userPassword, twitterAccessToken, twitterSecret, userTwitterName) {

    return (dispatch) => {
        firebase.auth().createUserWithEmailAndPassword(userEmail, userPassword).then((response) => {

            console.log("new user created...now signing in user for registration to backend")
            firebase.auth().signInWithEmailAndPassword(userEmail, userPassword).then((response) => {
                console.log("user signed in!.....")
                var user = firebase.auth().currentUser;
                if (user) {
                    axios.post('/api/registernewuser',
                        {
                            businessName, userEmail: userEmail, userId: user.uid
                        })
                        .then(function (response) {
                            console.log("new user created in backend... ")
                            dispatch(routeToLoginPage())
                            dispatch(uiActions.showSnackbar({ color: "success", message: "Your Account has been created.Please Login Now!" }))
                            dispatch({ type: "FIREBASE_NEW_USER_REGISTRATION_STATUS", payload: true })
                            dispatch(verifyNewUser())
                        })
                        .catch(function (error) {
                            dispatch(uiActions.showSnackbar({ color: "danger", message: "Error Occured" + error }))
                        })
                }


            }).catch((error) => {
                console.log("Error Occured When Creating Your Account. Please Try Again", error)
                var errorCode = error.code;
                var errorMessage = error.message;
                dispatch(uiActions.showSnackbar({ color: "danger", message: errorMessage }))
                // ...
            });



        }).catch(function (error) {
            // Handle Errors here.
            console.log("error occured when creating user in FIREBASE", error)
            var errorCode = error.code;
            var errorMessage = error.message;
            dispatch(uiActions.showSnackbar({ color: "danger", message: errorMessage }))
            // ...
        });


    }
}


export function verifyNewUser() {

    return (dispatch) => {
        var user = firebase.auth().currentUser;
        console.log("current active user", user)

        if (user) {
            // User is signed in.
            user.sendEmailVerification().then(function (response) {
                // Email sent.
                dispatch(uiActions.showSnackbar({ color: "danger", message: "Verification Link Sent to Email: " + user.email }))
            }).catch(function (error) {
                // An error happened.
                dispatch(uiActions.showSnackbar({ color: "danger", message: "Error Occured, Please Click to Verify again" }))
            });

        } else {
            // No user is signed in.
            dispatch(uiActions.showSnackbar({ color: "danger", message: "No Active Account.Please Contact Support" }))
        }
        ;


    }
}




export function resetUserPassword(userEmail) {

    return (dispatch) => {
        console.log("action to reset password for ", userEmail, "...dispatched")
        firebase.auth().sendPasswordResetEmail(userEmail).then((response) => {
            console.log("reset password sent to mail.....", response)
            dispatch(uiActions.showSnackbar({ color: "danger", message: " Reset Link Sent to your Mail" }))

        }).catch((error) => {
            dispatch(uiActions.showSnackbar({ color: "danger", message: "Error resetting password.Please try again" }))
            console.log("error occured when signing in new user:", error)
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
        });


    }
}

export function isUserSignedIn(store) {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                console.log("user is signed in", user.uid)
                resolve(user.uid);

            }
            else {
                console.log("user not signed in!")
                reject("user not signed in")
            }
        })


    })

}

export function checkLoggedInUser() {

    return function (dispatch) {
        firebase.auth().onAuthStateChanged(function (user) {
            console.log("Firebase user::", user)
            if (user) {
                // User is signed in.
                var displayName = user.displayName;

                dispatch({
                    type: "FIREBASE_USER_SIGNED_IN", payload: {
                        uid: user.uid,
                        providerData: user.providerData,
                        photoURL: user.photoURL,
                        emailVerified: user.emailVerified,
                        email: user.email,
                        isUserSignedIn: true
                    }
                })
            } else {
                dispatch({
                    type: "FIREBASE_USER_SIGNED_IN", payload: {
                        uid: "N/A",
                        providerData: "N/A",
                        photoURL: "N/A",
                        emailVerified: false,
                        email: "N/A",
                        userStatus: "N/A",
                        isUserSignedIn: false
                    }
                })
            }
        });
    }




}




export function signOutUserFirebase() {

    return ((dispatch) => {

        firebase.auth().signOut().then(function (response) {
            console.log("user signed out", response)
            dispatch({
                type: "FIREBASE_USER_SIGNED_IN", payload: {
                    uid: "N/A",
                    providerData: "N/A",
                    photoURL: "N/A",
                    emailVerified: false,
                    email: "N/A",
                    userStatus: "N/A",
                    isUserSignedIn: false
                }
            })
        }).catch(function (error) {
            dispatch(uiActions.showSnackbar({ color: "danger", message: "Error Occured" + error }))
            console.log("error occured signing out user", error)

        })

    })

}



export function fetchTwitterAccessToken() {
    return function (dispatch) {
        firebase.auth().signInWithPopup(provider).then(function (result) {
            // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
            // You can use these server side with your app's credentials to access the Twitter API.
            dispatch({ type: "MENTIONS_FETCH_TWITTER_ACCESS_TOKENS_FINISHED", payload: result })
            console.log("results", result)
            var token = result.credential.accessToken;
            var secret = result.credential.secret;
            // The signed-in user info.
            var user = result.user;
            // ...
        }).catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            dispatch(uiActions.showSnackbar({ color: "danger", message: "Error Occured" + error }))
            // ...
        });

    }
}

export function handleRegistrationFormErrors(message) {
    return function (dispatch) {

        dispatch(uiActions.showSnackbar({ color: "danger", message: message }))
        // ...


    }
}



// [START auth_handle_reset_password]
export function handleResetPassword(actionCode, newPassword) {
    return (dispatch, getState) => {
        // Localize the UI to the selected language as determined by the lang
        // parameter.

        // Verify the password reset code is valid.

        auth.verifyPasswordResetCode(actionCode).then((email) => {
            console.log("the user email", email)
            var accountEmail = email;

            // TODO: Show the reset screen with the user's email and ask the user for
            // the new password.


            // Save the new password.
            auth.confirmPasswordReset(actionCode, newPassword).then((resp) => {
                console.log("password reset", resp)
                dispatch({ type: "FIREBASE_HANDLE_USER_RESET_PASSWORD", payload: true })
                // Password reset has been confirmed and new password updated.

                // TODO: Display a link back to the app, or sign-in the user directly
                // if the page belongs to the same domain as the app:
                // auth.signInWithEmailAndPassword(accountEmail, newPassword);

                // TODO: If a continue URL is available, display a button which on
                // click redirects the user back to the app via continueUrl with
                // additional state determined from that URL's parameters.

            }).catch((error) => {
                console.log("An error occured resetting password")
                dispatch({ type: "FIREBASE_HANDLE_USER_RESET_PASSWORD", payload: false })
                // Error occurred during confirmation. The code might have expired or the
                // password is too weak.
            });
        }).catch((error) => {
            dispatch(handleRegistrationFormErrors("Invalid or Expired Token: Please Try Again!"));
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
        });

    }
}
// [END auth_handle_reset_password]

// [START auth_handle_recover_email]
export function handleRecoverEmail(actionCode, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    var restoredEmail = null;
    // Confirm the action code is valid.
    auth.checkActionCode(actionCode).then((info) => {
        // Get the restored email address.
        restoredEmail = info['data']['email'];

        // Revert to the old email.
        return auth.applyActionCode(actionCode);
    }).then(() => {
        // Account email reverted to restoredEmail

        // TODO: Display a confirmation message to the user.

        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        auth.sendPasswordResetEmail(restoredEmail).then(() => {
            // Password reset confirmation sent. Ask user to check their email.
        }).catch((error) => {
            // Error encountered while sending password reset code.
        });
    }).catch((error) => {
        // Invalid code.
    });
}
// [END auth_handle_recover_email]

// [START auth_handle_verify_email]
export function handleVerifyEmail(actionCode, continueUrl, lang) {
    return (dispatch, getState) => {

        // Localize the UI to the selected language as determined by the lang
        // parameter.
        // Try to apply the email verification code.
        auth.applyActionCode(actionCode).then((resp) => {
            console.log("firebaseActions()::emailVerified::", resp)
            // Email address has been verified.

            // TODO: Display a confirmation message to the user.
            // You could also provide the user with a link back to the app.

            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.

            //let us sign out user
            dispatch({
                type: "FIREBASE_USER_SIGNED_IN", payload: {
                    uid: "N/A",
                    providerData: "N/A",
                    photoURL: "N/A",
                    emailVerified: false,
                    email: "N/A",
                    userStatus: "N/A",
                    isUserSignedIn: false
                }
            })
            dispatch({ type: "FIREBASE_HANDLE_USER_EMAIL_VERIFICATION", payload: true })
        }).catch((error) => {
            console.log("firebaseActions()::emailNotVerified::", error)
            dispatch({ type: "FIREBASE_HANDLE_USER_EMAIL_VERIFICATION", payload: false })
            dispatch(handleRegistrationFormErrors("Invalid or Expired Token: Please Verify Your Email Address Again"));
            // Code is invalid or expired. Ask the user to verify their email address
            // again.
        });

    }
}
// [END auth_handle_verify_email]

